const searchParams = new URL(window.location.href).searchParams;
searchParams.forEach((value, param) => setSetting(param, value));

export function getSetting(name) {
    return localStorage.getItem(name);
}

export function setSetting(name, value) {
    return localStorage.setItem(name, value);
}

export function getExtSetting(name) {
    return sendMessageToExt({task: "getSetting", name});
}

export function setExtSetting(name, value) {
    return sendMessageToExt({task: "setSetting", name, value});
}

export function sendMessage(msgName, msgObj){
    return sendMessageToExt({task: msgName, ...msgObj});
}

export function sendMessageToExt(msgToPost){
    return new Promise(function (resolve, reject){
        var receiveMessage = function(e){
            if (e.data  && e.data.msg == "msgResponse"){
                window.removeEventListener("message", receiveMessage, false);
                resolve(e.data.res);
            }
        }
        window.addEventListener("message", receiveMessage, false);
        window.parent.postMessage(msgToPost, "*");
        setTimeout(function() {
            reject(new Error('message timed out'));
        }, 500);
    });
}

export function getGuid() {
    return getSetting("guid") && Promise.resolve(getSetting("guid")) ||
        sendMessageToExt({task: "getSetting", name: "guid"});
}
